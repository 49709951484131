<template>
  <div class="financingneeds">
    <div class="financingneeds_title">发布融资需求</div>

    <div class="financingneeds_content">
      <el-form
        :model="formLabelAlign"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="拟融资额度" prop="nums">
          <div class="financingneeds_inp">
            <el-input
              v-model.number="formLabelAlign.nums"
              placeholder="请输入拟融资额度"
            ></el-input>
          </div>
          <span class="financingneeds_font">万元</span>
        </el-form-item>

        <el-form-item label="资金用途" prop="use">
          <el-input
            type="textarea"
            autosize
            class="financingneeds_textarea"
            placeholder="请输入资金用途"
            v-model="formLabelAlign.use"
          >
          </el-input>
          <!-- <el-radio v-model="value1" :label="1">产品研发</el-radio>
                    <el-radio v-model="value1" :label="2">资金周转</el-radio>
                    <el-radio v-model="value1" :label="3">其他</el-radio>
					<div class="financingneeds_inp" v-if='value1 == 3'>
						<el-input v-model="formLabelAlign.use" placeholder='请说明资金用途'></el-input>
					</div> -->
        </el-form-item>

        <el-form-item label="是否已融资">
          <el-radio v-model="value2" :label="1">是</el-radio>
          <el-radio v-model="value2" :label="2">否</el-radio>
          <div class="financingneeds_inp" v-if="value2 == 1">
            <el-input
              v-model="formLabelAlign.whethe"
              placeholder="请输入已融资额度"
            ></el-input>
          </div>
          <span class="financingneeds_font" v-if="value2 == 1">万元</span>
        </el-form-item>

        <el-form-item label="已融资渠道" v-if="value2 == 1">
          <el-radio v-model="value3" :label="1">银行</el-radio>
          <el-radio v-model="value3" :label="2">小额贷款公司</el-radio>
          <el-radio v-model="value3" :label="3">其他</el-radio>
          <div class="financingneeds_inp" v-if="value3 == 3">
            <el-input
              v-model="formLabelAlign.channel"
              placeholder="请说明融资渠道"
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item label="可提供的担保方式">
          <el-checkbox-group v-model="formLabelAlign.checkList" :max="1">
            <el-checkbox
              v-for="(item, index) in checkBox1"
              :key="index"
              :label="item.cate_name"
              @change.native="clickCheck1(item)"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="可接受的还款方式">
          <el-checkbox-group v-model="formLabelAlign.moneyList" :max="1">
            <el-checkbox
              v-for="(item, index) in checkBox2"
              :key="index"
              :label="item.name"
              @change.native="clickCheck2(item)"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="需求描述" prop="textarea">
          <div style="display: flex; align-items: flex-start;">
            <el-select
              v-model="formLabelAlign.need"
              placeholder="请选择需求"
              clearable
              style="margin-right: 20px;"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :value="item"
              >
              </el-option>
            </el-select>

            <el-input
              :disabled="!formLabelAlign.need"
              type="textarea"
              autosize
              class="financingneeds_textarea"
              placeholder="请输入内容"
              v-model="formLabelAlign.textarea"
            >
            </el-input>
          </div>
        </el-form-item>

        <!--  <el-form-item label="联系人" prop='name'>
					<div class="financingneeds_inp">
						<el-input v-model="formLabelAlign.name" placeholder='请输入联系人姓名'></el-input>
					</div>
			    </el-form-item>

			    <el-form-item label="联系电话" prop='phone'>
					<div class="financingneeds_inp">
						<el-input v-model.number="formLabelAlign.phone" placeholder='请输入联系电话'></el-input>
					</div>
			    </el-form-item>

			    <el-form-item label="验证码" prop='pass'>
					<div class="financingneeds_inp">
						<div class="financingneeds_user_line">
					  	 	<el-input v-model="formLabelAlign.pass" placeholder='请输入验证码' class='financingneeds_inp1'></el-input>
					  	 	<div class="financingneeds_code" @click='$throttle(sendCode)' v-if='flag'>发送验证码</div>
					  	 	<div class="financingneeds_code resigter_wait" v-else>{{num}}s 后重新发送</div>
					  	</div>
					</div>
			    </el-form-item> -->

        <el-form-item label="电子邮箱" prop="email">
          <div class="financingneeds_inp">
            <el-input
              v-model="formLabelAlign.email"
              placeholder="请输入电子邮箱"
            ></el-input>
          </div>
        </el-form-item>

        <div class="financingneeds_submit" @click="$throttle(onSubmit)">
          发布需求
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系电话不能为空'));
      }

      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (!this.$regPhone.test(value)) {
            callback(new Error('联系电话格式错误'));
          } else {
            callback();
          }
        }
      }, 500);
    };
    const checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('邮箱不能为空'));
      }

      setTimeout(() => {
        if (!this.$regEmail.test(value)) {
          callback(new Error('邮箱格式错误'));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      num: 60,
      flag: true,
      options: ['融资需求', '担保需求', '征信需求'],
      formLabelAlign: {
        nums: '',
        use: '',
        need: '',
        whethe: 0,
        channel: '',
        checkList: [],
        moneyList: [],
        describe: '',
        textarea: '',
        name: '',
        phone: '',
        email: '',
        pass: '',
      },
      rules: {
        nums: [
          { required: true, message: '拟融资额度不能为空' },
          { type: 'number', message: '拟融资额度必须为数字值' },
        ],
        use: [{ required: true, message: '资金用途不能为空' }],
        textarea: [{ required: true, message: '需求描述不能为空' }],
        name: [{ required: true, message: '联系人姓名不能为空' }],
        pass: [{ required: true, message: '验证码不能为空' }],
        email: [{ required: true, validator: checkEmail, trigger: 'blur' }],
        phone: [{ required: true, validator: checkPhone, trigger: 'blur' }],
      },
      checkBox1: [],
      checkBox2: [
        {
          name: '等额本息',
          id: 1,
        },
        {
          name: '等额本金',
          id: 2,
        },
        {
          name: '先息后本',
          id: 3,
        },
        {
          name: '其他',
          id: 4,
        },
      ],
      value1: 1,
      value2: 1,
      value3: 1,
      value4: 1,
      tabId: [],
      labId: [],
    };
  },
  methods: {
    //获取标签id
    clickCheck1(item) {
      let _this = this;

      if (this.tabId.length > 0) {
        if (this.tabId.includes(item.id)) {
          console.log('a');
          let index = this.tabId.findIndex((num) => num == item.id);
          this.tabId.splice(index, 1);
          item.checked = false;
        } else {
          console.log('b');
          this.tabId.push(item.id);
          item.checked = true;
        }
      } else {
        console.log('c');
        this.tabId.push(item.id);
        item.checked = true;
      }
      console.log(this.tabId);
    },
    //获取标签id
    clickCheck2(item) {
      let _this = this;

      if (this.labId.length > 0) {
        if (this.labId.includes(item.id)) {
          console.log('a');
          let index = this.labId.findIndex((num) => num == item.id);
          this.labId.splice(index, 1);
          item.checked = false;
        } else {
          console.log('b');
          this.labId.push(item.id);
          item.checked = true;
        }
      } else {
        console.log('c');
        this.labId.push(item.id);
        item.checked = true;
      }
      console.log(this.labId);
    },
    // 分类获取
    getProCate() {
      let data = { type: 2 };

      this.$get('/pro_cate', data).then((res) => {
        if (res.data.status == 1) {
          this.checkBox1 = res.data.result;
        }
      });
    },
    onSubmit() {
      // let data = {
      // 	token: localStorage.eleToken ? localStorage.eleToken : '',
      // 	quota: this.formLabelAlign.nums,
      // 	capital_comment: this.value1 == 1 ? '产品研发' : this.value1 == 2 ? '资金周转' : this.formLabelAlign.use,
      // 	reason: '',
      // 	is_finance: this.value2 == 1 ? 1 : this.value2 == 2 ? 0 : '',
      // 	finance_amount: this.value2 == 1 ? this.formLabelAlign.whethe : '',
      // 	guarantee_id: this.tabId[0],
      // 	repayment_mode: this.labId[0],
      // 	explain: this.formLabelAlign.textarea,
      // 	contacts: this.formLabelAlign.name,
      // 	mobile: this.formLabelAlign.phone,
      // 	email: this.formLabelAlign.email,
      // 	finance_channel: this.value2 == 1 ? 1 : this.value2 == 2 ? 0 : '',
      // 	finance_or_channel: this.value3 == 3 ? this.formLabelAlign.channel : '',
      // 	code: this.formLabelAlign.pass
      // }
      //
      //
      const loading = this.$loading({
        lock: true,
        text: '正在发布...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : '',
            quota: this.formLabelAlign.nums,
            capital_comment: this.formLabelAlign.use,
            reason: '',
            is_finance: this.value2 == 1 ? 1 : this.value2 == 2 ? 0 : '',
            finance_amount: this.value2 == 1 ? this.formLabelAlign.whethe : '',
            guarantee_id: this.tabId[0],
            repayment_mode: this.labId[0],
            explain:
              this.formLabelAlign.need + '：' + this.formLabelAlign.textarea,
            email: this.formLabelAlign.email,
            finance_channel: this.value2 == 1 ? 1 : this.value2 == 2 ? 0 : '',
            finance_or_channel:
              this.value3 == 3 ? this.formLabelAlign.channel : '',
          };

          this.$post('/demand_add', data).then((res) => {
            let type = res.data.status == 1 ? 'success' : 'error';

            this.$message({
              message: res.data.msg,
              type,
              duration: 1000,
            });
            // 关闭加载效果
            loading.close();
            if (res.data.status == 1) {
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            }
          });
        } else {
          console.log('error submit!!');
          this.$message.error(res.data.message);
          return false;
        }
      });
    },
    // 发送验证码
    sendCode() {
      if (this.formLabelAlign.phone == '') {
        this.$message({
          message: '联系电话不能为空',
          type: 'error',
        });
        return false;
      }

      if (!this.$regPhone.test(this.formLabelAlign.phone)) {
        this.$message({
          message: '联系电话格式错误',
          type: 'error',
        });
        return false;
      }

      let data = { phone: this.formLabelAlign.phone };

      this.$get('/get_code', data).then((res) => {
        console.log(res.data.status, 889);
        if (res.data.status == 1) {
          if (this.timer) {
            clearInterval(this.timer);
          }

          this.flag = false;
          this.timer = setInterval(() => {
            this.num--;

            if (this.num == 0) {
              clearInterval(this.timer);

              this.flag = true;
              this.num = 60;
            }
          }, 1000);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error',
          });
        }
      });
    },
  },
  mounted() {
    this.getProCate();
  },
};
</script>

<style scoped>
@import './financingneeds.css';
</style>
